import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Link } from 'gatsby';

interface Props {}

const Index: React.FC = () => {
  return (
    <DefaultLayout>
      <div className='four-oh-four container max-w-md mx-auto mt-10'>
        <h1 className='text-4xl text-primary-base mb-16 font-bold'>Oops, that's a 404</h1>
        <Link to='/'>Go to Home Page</Link>
      </div>
    </DefaultLayout>
  );
};

export default Index;
